* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(to bottom, #000000, rgb(10, 9, 19));
  cursor:  none;

}

.custom-cursor {
  cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhLS0gTGljZW5zZTogQ0MwLiBNYWRlIGJ5IFNWRyBSZXBvOiBodHRwczovL3d3dy5zdmdyZXBvLmNvbS9zdmcvMjkzNDM4L25hdmlnYXRpb24tY3Vyc29yIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiAgd2lkdGg9IjM2cHgiIGhlaWdodD0iMzZweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQxIiB4MT0iMCUiIHkxPSIwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0eWxlPSJzdG9wLWNvbG9yOnJnYig1LDU1LDIwMCk7c3RvcC1vcGFjaXR5OjEiIC8+CiAgICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3R5bGU9InN0b3AtY29sb3I6cmdiKDI1NSwwLDIwMCk7c3RvcC1vcGFjaXR5OjEiIC8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cGF0aCBzdHlsZT0iZmlsbDp1cmwoI2dyYWQxKTsiIGQ9Ik03MC43MDQsMGwzOTIuMDQsMzI5LjUyOGwtMjU0LjQ0OC0xNi4xODRMNDkuMjU2LDUxMkw3MC43MDQsMHoiLz4KPC9zdmc+Cgo=), auto;
} 

.canvas-container {
  width: 100%;
  height: 100%; /* Imposta l'altezza al 100% dell'altezza della viewport */
  position: relative;
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* styles.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181820;
  z-index: 1000;
}

.loader::after {
  content: '';
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.8);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Stilizza la scrollbar per WebKit/Blink browsers come Chrome, Safari, ecc. */
::-webkit-scrollbar {
  width: 10px; /* Larghezza della scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Colore del thumb */
  border-radius: 5px; /* Angoli arrotondati del thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Colore del thumb quando si passa sopra */
}

/* Stilizza la scrollbar per Firefox */
* {
  scrollbar-width: thin; /* "auto" o "thin" */
  scrollbar-color: #888 #474747; /* Colore del thumb e colore dello sfondo della scrollbar */
}

/* Nota: non c'è un modo diretto per stilizzare lo stato :hover del thumb in Firefox usando solo CSS al momento */
