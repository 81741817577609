/* @keyframes wave {
  0%, 100% {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  25% {
    clip-path: polygon(50% 0%, 10% 90%, 90% 90%);
  }
  50% {
    clip-path: polygon(50% 10%, 0% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 0%, 10% 90%, 90% 90%);
  }
} */

.custom-cursor {
  position: fixed;
  width: 25px;
  height: 30px;
  background-image: linear-gradient(to right, rgba(226, 226, 226, 0.8), rgba(87, 69, 167, 0.8));
  border: 0px solid rgb(255, 255, 255);

opacity: 2;
  transform: translate(-50%, -50%) rotate(-25deg); /* Rotazione verso sinistra */
  pointer-events: none;
  z-index: 9999;
 
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%); /* Forma triangolare */
  animation: wave 5s infinite ease-in-out;
}

@media (hover: none) and (pointer: coarse) {
  .custom-cursor {
    display: none;
  }
}
